function helpWin(questionid, winTitle, helpText, helpWinURL) {
  $('#helpWinDialog').show();
  var $contents = $('#helpWinDialog').children('#helpContents').first();
  $contents.empty();
  if (helpWinURL == undefined) {
    $contents.html(helpText);
  } else {
    $contents.load(helpWinURL);
  }
  $('#helpWinDialog').children('#helpSpinner').hide();
  $('#helpWinDialog').dialog({
    width: 'auto',
    maxWidth: '20rem',
    modal: false,
    title: winTitle,
    classes: window.monocleDialogClasses
  });
}

function isHelpDialogVisible() {
  //return helpIsSticky;
  return $('.ui-dialog').filter(':visible').length > 0;
}

$(document).ready(function () {
  $('[helpid]')
    .css({
      cursor: 'pointer',
      'text-decoration': 'underline',
    })
    .click(function (event) {
      if ($(event.target).hasClass('copyPreviousYearData')) {
        return;
      }
      helpWin(
        $(this).attr('helpid'),
        $(this).attr('helpWinTitle'),
        $(this).attr('helpText'),
        $(this).attr('helpWinURL'),
      );
    });

  $('[helpid]').tooltip({
    position: { my: 'left-26 bottom-20', at: 'right bottom' },
    track: true,
    items: '*',
    content: function () {
      return $(this).attr('helptext');
    },
  });

  $('[data-tooltip-content]')
    .css({
      cursor: 'pointer',
      'text-decoration': 'underline',
    })
    .each(function () {
      var flipped = !!$(this).attr('data-tooltip-flipped'),
        flippedClass = flipped ? 'flipped' : '',
        myLoc = flipped ? 'right+26 bottom-20' : 'left-26 bottom-20',
        wide = !!$(this).attr('data-tooltip-wide'),
        wideClass = wide ? 'tooltip-wide' : '',
        instantHide = !!$(this).attr('data-tooltip-instanthide'),
        hasPopup = !!$(this).attr('data-tooltip-popup');
      $(this).tooltip({
        position: { my: myLoc, at: 'left top' },
        track: true,
        hide: !instantHide,
        items: '*',
        tooltipClass: flippedClass + ' ' + wideClass,
        content: function () {
          return $(this).closest('[data-tooltip-content]').attr('data-tooltip-content');
        },
      });

      if (hasPopup) {
        $(this).click(function (e) {
          e.preventDefault();
          helpWin('tooltip', '', $(this).attr('data-tooltip-content'));
        });
      }
    });

  // This div (helpWinDialog) becomes a jquery ui dialog - Jared 4/15/11
  $('body').append(
    '<div id="helpWinDialog" style="display:none;">' +
      '<div id="helpSpinner" style="margin:10px auto; width:50px;">' +
      '<img src="/images/icons/spinner.gif" />' +
      '</div>' +
      '<div id="helpContents"></div>' +
      '</div>',
  );
});

window.helpWin = helpWin;
window.isHelpDialogVisible = isHelpDialogVisible;
